<template>
  <div>
    <GenericTable
      ref="lista"
      name="Fornecedor"
      title="Fornecedor"
      add-route="fornecedorAdicionar"
      edit-route="fornecedorEditar"
      :cols="['Nome']"
      :cols-name="['nome']"
      :cols-map="i => [i.nome]"
      :route="fornecedorRoute"
      :permissionsToWrite="['rw_material']"
      idKey="id_fornecedor"
      :filters="filters"
      @clear-filters="clearFilters"
    >
       <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Nome</label>
              <b-form-input
                v-model="filters.nome"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>

</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      fornecedorRoute: 'fornecedor',
      filters: {
        nome: '',
      },
    };
  },
  computed: {
    ...mapState(['filtersStored', 'previousRoute']),
  },
  created() {
    if (this.filtersStored.fornecedor) {
      if (['fornecedorEditar'].includes(this.previousRoute.name)) {
        this.filters = { ...this.filtersStored.fornecedor };
      } else {
        this.$store.commit('setFiltersStored', {});
      }
    }
  },
  methods: {
    clearFilters() {
      this.filters.nome = '';
    },
  },
};
</script>
